<template>
  <div class="page">
    <!-- banner图 -->
    <BannerHead
      :bannerImg="bannerImg"
      :bigTitle_1="bigTitle_1"
      :smallTitle_1="smallTitle_1"
    ></BannerHead>

    <!-- 核心功能 -->
    <CoreFunction :spanList="spanList" :coreImg="coreImg"></CoreFunction>

    <!-- 差异优势 -->
    <Different :diffImg="diffImg" :diffList="diffList"></Different>

    <!-- 应用场景 -->
    <Application :appImg="appImg" :appliList="appliList"></Application>

    <!-- 尾部 -->
    <Footering></Footering>
  
  </div>
</template>

<script>
// banner
import BannerHead from "@/components/centerPage/banner_head.vue";
import CoreFunction from "@/components/centerPage/core_function.vue";
import Different from "@/components/centerPage/differential_adv.vue";
import Application from "@/components/centerPage/application.vue";

import banner1 from "@/assets/img/children/banner_1.png";
import hexin from "@/assets/img/children/hexin.png";
import chayi from "@/assets/img/children/chayi.png";
import app14 from "@/assets/img/children/app14.png";

// foote组件
import Footering from "../../components/foot/viewfooter.vue";

// 公用组件
import "../../assets/css/public.css";

export default {
  components: {
    BannerHead,
    CoreFunction,
    Different,
    Application,
    Footering
  },
  data() {
    return {
      bannerImg: banner1,
      coreImg: hexin,
      bigTitle_1: "gpt跨境业务应用",
      smallTitle_1:
        "预置chatgpt在跨境电商各场景应用的prompt指令，更好的利用chatgpt解决业务问题；",

      // 核心功能
      spanList: [
        {
          firstSpan: "跨境电商领域logo检测",
          secondSpan:
            "对原有标题通顺度进⾏优化，解决词堆砌、重要信息不凸显、不符合阅读习惯等问题。",
        },
        {
          firstSpan: "自定义logo信息库",
          secondSpan:
            "领域数据集自建库，自定义logo小图的详细信息（如 品牌、型号、风险等级等）",
        },
      ],

      //   差异优势
      diffImg: chayi,
      diffList: [
        {
          diffFirst: "跨境场景数据训练",
          diffSecond:
            "提升图⽂相关性的多模态模型，利⽤近7000w跨境电商数据训练。对跨境数据集能够更好的识别效果。",
        },
        {
          diffFirst: "标题⻓尾词布局",
          diffSecond:
            "已在⾃身业务shopping、seo流量场景落地应⽤，并在单item引流uv、item均gmv等指标上 提升10%+。",
        },
      ],
      
      //   应用场景
      appImg: app14,
      appliList: [
        {
          appliFirst: "商品信息质量优化",
          appliSecond:
            "优化站内商品信息质量，上架前通过标题等基础信息生成、优化商品短描、卖点、详描，生成商品的FAQ、补充商品属性信息。",
        },
        {
          appliFirst: "站外引流素材生成",
          appliSecond:
            "通过gpt在seo场景生成引流博客、优化商品标题及TDK商品页面描述，生成引流视频中商品介绍；社媒引流中，titok标题、#标签词、post广告语等生成；",
        },
        {
          appliFirst: "站内营销场景",
          appliSecond:
            "通过商品已有的标题、属性等信息生成商品卖点描述、个性化大促会场营销语、推荐理由等；书写商品推广营销邮件、客户挽回邮件等。",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
